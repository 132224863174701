<template>
  <div>
    <div class="row justify-content-between">
      <div class="col-md-6">
          <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('days')"  v-bind:class="{'btn-info' : btnActive.days, 'btn-outline-info' : btnActive.days == false   }">Hari Ini</button>
          <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('month')"  v-bind:class="{'btn-info' : btnActive.month, 'btn-outline-info' : btnActive.month == false  }">Bulan Ini</button>
          <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('lastMonth')"  v-bind:class="{'btn-info' : btnActive.lastMonth, 'btn-outline-info' : btnActive.lastMonth == false  }">Bulan Kemarin</button>
          <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('year')"  v-bind:class="{'btn-info' : btnActive.year, 'btn-outline-info' : btnActive.year == false  }">Tahun Ini</button>
          <button class="btn mx-1 btn-timeseries "  v-bind:class="{'btn-info' : btnActive.period, 'btn-outline-info' : btnActive.period == false  }" @click="btnPeriodeOnClick">Periode</button>
      </div>

      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared
              ><i class="fas fa-search"></i
            ></b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.search"
            placeholder="Cari Keterangan"
            @keyup="filterBySearch"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold"
          >{{recordData}} Baris Data</span>
      </div>
      
      <div class="col-md-6" v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>
            
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <template #prepend>
              <b-button squared @click="filterByPeriode" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilterPeriode" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
                <template #prepend>
                  <b-button squared @click="filterByPeriode" variant="success"
                    >Cari</b-button
                  >
                  <b-button squared @click="resetFilterPeriode" variant="danger"
                    >Reset</b-button
                  >
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
    </div>

    <b-table
      class="mt-3"
      striped
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive="sm"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '15%' : '' }"
        />
      </template>
      <template #cell(actions)="data">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          @click="
            $router.push({
              path: '/pharmacy/pharmacy-expenses/detail/' + data.item.id,
            })
          "
        >
          <i class="fas fa-eye px-0"></i>
        </b-button>
        <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          v-if="manipulateBtn == true"
          @click="
            $router.push({
              path: '/pharmacy/pharmacy-expenses/edit/' + data.item.id,
            })
          "
        >
          <i class="fas fa-edit px-0"></i>
        </b-button>
        <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          v-if="manipulateBtn == true"
          @click="btnDeleteOnClick(data.item.id)"
        >
          <i class="fas fa-trash px-0"></i>
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import moment from 'moment';

export default {
  data() {
    return {
      // Filter
      filter: {
        search: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      lastPeriodType:"days",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData:0,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date_display",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "amount",
          label: "Nominal (Rp)",
          sortable: true,
        },
        {
          key: "created_by_name",
          label: "Penginput",
          sortable: true,
        },
        {
          key: "expense_method",
          label: "Metode Pengeluaran",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // items
      items: [],
      manipulateBtn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    filterBySearch() {
      this.filter.start_date = "";
      this.filter.end_date = "";

      if(this.filter.search == ''){
        this.btnActiveOnClick(this.lastPeriodType)
      }
      this.pagination();
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      this.btnActiveOnClick(this.lastPeriodType)
      this.pagination();
    },

    filterByPeriode() {
      this.pagination();
    },

    async pagination() {
      let filterParams = `&search=${this.filter.search}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "pharmacy-expenses",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("pharmacy-expenses/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");
        if (access_right[a] == "1404") {
          this.manipulateBtn = true;
        }
      }
    },

    btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "days"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "month"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1,'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1,'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')


        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "lastMonth"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        this.lastPeriodType = "year"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },
  },

  mounted() {
    this.pagination();
    this.setActiveMenu();
  },
};
</script>

<style>
</style>